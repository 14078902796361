import React from "react"

import styled from '@emotion/styled'
import { PatternDiv } from '../pattern-div/patternDiv';

export const TiltedDiv = styled(PatternDiv)`
  text-align: center;
  padding: 200px 0;
  margin: 50px 0;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
`


export const TiltedAccentTitle = (props) => <TiltedDiv>
  <div css={{
    maxWidth: "750px",
    margin: "0 auto"
  }}>
    <div css={{
      margin: "0 20px"
    }}>
      <h2 css={{ fontSize: "48px", textAlign: props.title.length < 50 ? "center" : "left", fontWeight: "bolder" }}>{props.title}</h2>
      <p css={{ fontSize: "16px", textAlign: "left" }}>{props.subtitle}</p>
      {props.children}
    </div>
  </div>
</TiltedDiv>