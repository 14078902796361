import React from "react";
import styled from '@emotion/styled';

const FeatureTitle = styled.span`
  font-weight: bold;
  line-height: 46px;
  @media(max-width: 350px) {
    font-size: 32px;
  }
  @media(min-width: 350px) {
    font-size: 32px;
  }
`;
export const FeatureBox = (props) => <div>
  <span css={{ fontWeight: "bold", color: "#0A4BBB", fontSize: "20px" }}>{props.rephrase}</span> <br />
  <div css={{ maxWidth: "450px", margin: "15px 0" }}>
    <FeatureTitle>{props.title}</FeatureTitle>
  </div>
  <div css={{ maxWidth: "450px" }}>
    <span css={{ fontSize: "20px" }}>{props.description}</span>
  </div>
</div>