import React from 'react';
import styled from '@emotion/styled'
import Image from 'react-shimmer'
import { Link } from 'gatsby';

const TestimonialAdDiv = styled.div`
  max-width: 900px;
  height: 215px;
  background-color: white;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;

  top: -200px;
  margin-bottom: -100px;
    
  @media (min-width: 900px) {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  @media (max-width: 950px) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
  }
`

const CenteredDiv = styled.div`
  max-width: 425px;
  
  @media (min-width: 425px) {
    margin: 0 auto;
  }
  @media (max-width: 425px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`

export const TestimonialAd = () => <TestimonialAdDiv>
  <Image
    src="/images/bridget-phonebank.png"
    width={284} height={215}
    className="d-none d-md-block"
    css={{ objectFit: 'cover', clipPath: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%)', height: '100%', width: '284px', float: 'left' }}
  />
  <CenteredDiv>
    <b>Bridget McCurry</b>, a field operator in North Carolina, switched to CrowdCall and saved her campaign almost $6,000 while significantly increasing volunteer retention.
    <br />
    <Link to="/nc-case-study">Read how Bridget uses CrowdCall →</Link>
  </CenteredDiv>
</TestimonialAdDiv>