import React from "react"
import { PatternDiv } from "../pattern-div/patternDiv";
import styled from '@emotion/styled'
import { NavBar } from "../navbar/navbar";

const TiltedDiv = styled(PatternDiv)`
  text-align: center;
  padding-bottom: 250px;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
`

const TitleText = styled.h1`
  font-family: 'League Spartan', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 60px;
  padding-top: 150px;
  @media (max-width: 991.98px) {
    font-size: 30px;
  }
`

const SubtitleContainer = styled.div`
  max-width: 600px;
  width: 70%;
  margin: 0 auto;
`

const SubtitleText = styled.p`
  font-size: 20px;
`

export const TitleHeader = (props) => <TiltedDiv>
  <NavBar />
  <div css={{ width: "80%", margin: "0 auto" }}>
    <TitleText>{props.title}</TitleText>
    {props.subtitle && <SubtitleContainer centered={props.subtitle.length < 50}>
      <SubtitleText>{props.subtitle}</SubtitleText>
    </SubtitleContainer>}
    {props.children}
  </div>
</TiltedDiv>